import {
  AutoComplete,
  BasicInput,
  Col,
  FormikInputDate,
  FormikInputInteger,
  FormikSelect,
  Loading,
  Modal,
  Panel,
  Row,
  SectionTitle,
  useShowNotification
} from '@elotech/components';
import { InlineButton, Label, TinyMCE } from 'common/components';
import { useLoading } from 'common/hooks';
import { TipoModeloEnum } from 'common/type/enum';
import FileSaver from 'file-saver';
import { Form, Formik } from 'formik';
import { useExportPdfQuestion } from 'hooks';
import { DEMONSTRA_PARAMETRO_EXPORTACAO_MODELO_DADOS } from 'pages/parametros/Parametros';
import { useCallback, useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import {
  ModeloDadosExporterProtocoloService,
  ModeloDadosService,
  ParametroService,
  ProcessoArquivoService
} from 'service';
import { groupBy } from 'utils';

const ParamTypes = {
  DATE: 'DATE',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER'
};

const ParamFields = {
  DATE: FormikInputDate,
  STRING: BasicInput,
  BOOLEAN: FormikSelect,
  NUMBER: FormikInputInteger
};

const ModeloDadosSearchMp = ({
  modelo,
  setModelo,
  template = '',
  setTemplate,
  assuntoModelo,
  onSaveTemplate,
  entityId,
  onAddFilesFromResponse
}) => {
  const [loading, setLoading] = useLoading();
  const [paramGroups, setParamGroups] = useState([]);
  const [formParams, setFormParams] = useState([]);
  const [showParamsModal, setShowParamsModal] = useState(false);
  const [showParametroExportacao, setShowParametroExportacao] = useState(false);
  const showNotification = useShowNotification();

  const exportPdfQuestion = useExportPdfQuestion({
    onSave: (template, name) => savePdf(template, name),
    onExport: template => exportPdf(template),
    modelo
  });

  useEffect(() => {
    ParametroService.findParametro(
      DEMONSTRA_PARAMETRO_EXPORTACAO_MODELO_DADOS
    ).then(({ data }) => {
      setShowParametroExportacao(data.valor === 'S');
    });
  }, []);

  const onPreviewModeloWithParams = (values = []) => {
    setShowParamsModal(false);
    setFormParams(values);
    preview(modelo, values);
  };

  const preview = (modeloDados, paramValues = []) => {
    setShowParamsModal(false);
    if (modeloDados?.tipo === TipoModeloEnum.OFFICE) {
      return setLoading(
        ModeloDadosService.previewOffice({
          modeloDados,
          paramValues
        }).then(response => {
          const { headers, data } = response;
          const blob = new Blob([data], {
            type: headers['content-type']
          });
          FileSaver.saveAs(URL.createObjectURL(blob), modeloDados.nome);
        })
      );
    }

    return setLoading(
      ModeloDadosService.preview({
        modeloDados,
        paramValues
      }).then(({ data }) => setTemplate(data))
    );
  };

  const getParamsInitialValues = () => {
    if (
      paramGroups &&
      paramGroups.length === 0 &&
      formParams &&
      formParams.length === 0
    ) {
      const paramsArray =
        modelo?.sqls.flatMap(s => s.sqlModelo.parametros) || [];

      const paramsArrayAgrupadoBySql = groupBy(paramsArray, 'sqlIdentificador');

      setParamGroups(paramsArrayAgrupadoBySql);
      setFormParams(createFormObject(paramsArrayAgrupadoBySql));
      return paramsArrayAgrupadoBySql;
    }

    return formParams;
  };

  const modeloHasSqls = useCallback(
    (modeloDados = modelo) => modeloDados?.sqls && modeloDados?.sqls.length > 0,
    []
  );

  const getParamFieldType = (groupKey, paramKey) => {
    const group = paramGroups.find(p => p.field === groupKey);
    const param = group?.groupList?.find(p => p.identificador === paramKey);

    return param?.tipo;
  };

  const getFieldProps = (paramKey, paramIndex, groupKey, groupIndex, type) => {
    let fieldProps = {
      key: `${groupIndex}_${paramKey}_${paramIndex}`,
      size: 3,
      disabled: loading,
      name: `${groupIndex}.${groupKey}.${paramKey}`,
      label: paramKey
    };

    if (type === ParamTypes.BOOLEAN) {
      fieldProps = {
        ...fieldProps,
        getOptionLabel: value => (value === 'S' ? 'Sim' : 'Não'),
        getOptionValue: value => value,
        options: ['S', 'N']
      };
    }

    return fieldProps;
  };

  const renderParamsBasicInputs = (
    paramKey,
    paramIndex,
    groupIndex,
    groupKey
  ) => {
    const type = getParamFieldType(groupKey, paramKey);
    const fieldProps = getFieldProps(
      paramKey,
      paramIndex,
      groupKey,
      groupIndex,
      type
    );
    const ParamField = ParamFields[type];

    return ParamField ? <ParamField {...fieldProps} /> : null;
  };

  const renderSqlGroups = formProps =>
    formProps.values.map((group, groupIndex) => {
      const groupKeys = Object.keys(group);

      return groupKeys && groupKeys.length > 0 ? (
        <div key={`${groupIndex}_${groupKeys[0]}`}>
          <SectionTitle>{groupKeys[0]}</SectionTitle>
          <Row>
            {Object.keys(group[groupKeys[0]]).map((param, paramIndex) => {
              return renderParamsBasicInputs(
                param,
                paramIndex,
                groupIndex,
                groupKeys[0]
              );
            })}
          </Row>
        </div>
      ) : null;
    });

  const checkAllParamsPreenchidos = values =>
    values.some(value => {
      const valueKeys = Object.keys(value);
      const fields = valueKeys && valueKeys.length > 0 && value[valueKeys[0]];
      return fields && Object.keys(fields).some(paramKey => !fields[paramKey]);
    });

  const renderParamsModal = () => (
    <Modal onClose={() => setShowParamsModal(false)} maxWidth="75%">
      <Panel title="Inserir Parâmetros" isForm>
        <Formik
          enableReinitialize
          onSubmit={onPreviewModeloWithParams}
          initialValues={getParamsInitialValues()}
        >
          {formProps => {
            return (
              <Form>
                {renderSqlGroups(formProps)}
                <Row>
                  <Col md={12} sm={12} className="mt-xs right">
                    <FormGroup>
                      <InlineButton
                        label="Salvar Parâmetros"
                        onClick={formProps.submitForm}
                        disabled={checkAllParamsPreenchidos(formProps.values)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Panel>
    </Modal>
  );

  const onChangeModelo = (_, value) => {
    setParamGroups([]);
    setFormParams([]);

    if (value) {
      setModelo(value);

      if (modeloHasSqls(value)) {
        setShowParamsModal(true);
      } else {
        preview(value);
      }
    } else {
      setModelo(undefined);
    }
  };

  const exportPdf = currentTemplate => {
    setLoading(
      ModeloDadosExporterProtocoloService.exportarModeloPdf({
        ...modelo,
        templateHtml: currentTemplate
      })
    );
  };

  const savePdf = (currentTemplate, nameFile) => {
    if (currentTemplate) {
      return setLoading(
        ProcessoArquivoService.salvarArquivoPdf(
          entityId,
          currentTemplate,
          nameFile
        )
          .then(onAddFilesFromResponse)
          .then(() =>
            showNotification({
              level: 'success',
              message: 'Modelo salvo como PDF com sucesso'
            })
          )
      );
    }
  };

  const onSafeTemplateAndExportPdf = (currentTemplate, exportBeforeSave) => {
    exportPdfQuestion(currentTemplate, exportBeforeSave);
    saveTemplate();
  };

  const saveTemplate = () => {
    if (template) {
      return setLoading(
        ProcessoArquivoService.salvarArquivoTemplate(entityId, {
          contentHtml: template,
          nome: modelo?.nome || 'templateProcesso'
        })
          .then(onAddFilesFromResponse)
          .then(() =>
            showNotification({
              level: 'success',
              message: 'Modelo salvo como template com sucesso (.html)'
            })
          )
      );
    }
  };

  const executeParams = () => {
    getParamsInitialValues();
    const paramsBySql = createParamsArrayAgrupadoBySql();
    preview(modelo, createFormObject(paramsBySql));
  };

  const createParamsArrayAgrupadoBySql = () => {
    const paramsArray = modelo?.sqls?.flatMap(s => s.sqlModelo?.parametros);
    return groupBy(paramsArray, 'sqlIdentificador');
  };

  const createFormObject = params => {
    return params.map(group => {
      const formObject = {
        [group.field]: {}
      };

      group.groupList.forEach(
        p =>
          (formObject[group.field] = {
            ...formObject[group.field],
            [p.identificador]: p.identificador === 'id' ? entityId : undefined
          })
      );

      return formObject;
    });
  };

  return (
    <>
      {showParamsModal && !showParametroExportacao && executeParams()}
      {showParamsModal && showParametroExportacao && renderParamsModal()}
      <Loading loading={loading} />
      <Row>
        <Col sm={modeloHasSqls() ? 10 : 12} md={modeloHasSqls() ? 10 : 12}>
          <FormGroup>
            <Label htmlFor="modelo">Modelo</Label>
            <AutoComplete
              name="modelo"
              value={modelo}
              onSearch={search =>
                ModeloDadosService.findAllByAssunto(search, assuntoModelo)
              }
              getOptionLabel={value => `${value.nome}`}
              onSelect={onChangeModelo}
            />
          </FormGroup>
        </Col>
        {modeloHasSqls() && (
          <Col md={2} sm={2} className="mt-xs">
            <FormGroup>
              <InlineButton
                icon="fa fa-pencil-alt"
                label="Parâmetros"
                onClick={() => setShowParamsModal(true)}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <FormGroup>
        <Label>Template</Label>
        <TinyMCE
          value={template}
          onChange={setTemplate}
          actions={[
            {
              name: 'exportpdf',
              execute: value => exportPdfQuestion(value, true)
            }
          ]}
        />
      </FormGroup>
      <Row>
        <Col md={12} sm={12} className="mt-xs">
          <InlineButton
            icon="fa fa-file"
            label="Salvar Template"
            disabled={!template?.trim()}
            onClick={onSaveTemplate}
          />
          <InlineButton
            icon="fa fa-file-pdf"
            label="Salvar como Anexo (PDF)"
            disabled={!template?.trim()}
            onClick={() => exportPdfQuestion(template, false)}
          />
          <InlineButton
            icon="fa fa-file-pdf"
            label="Salvar Template e Salvar como anexo (PDF)"
            disabled={!template?.trim()}
            onClick={() => onSafeTemplateAndExportPdf(template, false)}
          />
        </Col>
      </Row>
    </>
  );
};

export { ModeloDadosSearchMp };
