import React, { useEffect, useState } from 'react';

import { DetalheConfiguracaoIntegracaoEnum } from '../../type/enum/DetalheConfiguracaoIntegracaoEnum';

export type DetalheConfiguracaoIntegracaoSelectProps = {
  size?: number;
  name: string;
  value: string;
  className?: string;
  onSelect: (name: string, value: string) => void;
};

type DetalheConfiguracaoIntegracao = {
  value: string;
  description: string;
};

const options: DetalheConfiguracaoIntegracao[] = [
  {
    value: DetalheConfiguracaoIntegracaoEnum.OUTRA,
    description: 'Outra'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.BLL,
    description: 'BLL'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.BNC,
    description: 'BNC'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.LICITANET,
    description: 'LICITANET'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.LICITAR,
    description: 'LICITAR'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.COMPRASNET,
    description: 'COMPRASNET'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.COMPRASBR,
    description: 'COMPRASBR'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.COMPRAS_PUBLICAS,
    description: 'Compras Públicas'
  },
  {
    value: DetalheConfiguracaoIntegracaoEnum.BBMNET,
    description: 'BBMNET'
  }
];

const DetalheConfiguracaoIntegracaoSelect: React.FC<
  DetalheConfiguracaoIntegracaoSelectProps
> = ({ name, value = '', onSelect, className }) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const onChange = ({ target }) => {
    setInternalValue(target.value);
    onSelect(name, target.value);
  };

  return (
    <>
      <label htmlFor={name} className="label">
        Integração
      </label>
      <select
        id={name}
        name={name}
        value={internalValue}
        onChange={onChange}
        className={className}
        data-testid="detalheConfiguracaoIntegracao"
      >
        {options.map(item => (
          <option key={item.value} value={item.value}>
            {item.description}
          </option>
        ))}
      </select>
    </>
  );
};

export { DetalheConfiguracaoIntegracaoSelect };
