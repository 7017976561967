import { HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';
import { CSSProperties } from 'styled-components';

type Props = {
  uri: any;
  identificador: string | number;
  style?: CSSProperties;
  target?: HTMLAttributeAnchorTarget;
  replace?: boolean;
};

const CustomLink = ({
  identificador,
  uri,
  style,
  target = '_blank',
  replace = true
}: Props) => {
  return (
    <Link target={target} rel="noopener noreferrer" to={uri} style={style}>
      {identificador}&nbsp;&nbsp;
      <i className="fas fa-external-link-alt blue-40" />
    </Link>
  );
};

export { CustomLink };
