import { alert } from 'common/components';
import { SlugUtils } from 'utils';

export type Props = {
  modelo:
    | {
        nome: string;
      }
    | undefined;
  onSave: (template: string, fileName: string | undefined) => void;
  onExport: (template: string) => void;
};

export const useExportPdfQuestion = ({ modelo, onSave, onExport }: Props) => {
  const showQuestion = (currentTemplate: string, exportBeforeSave: boolean) => {
    const title = exportBeforeSave ? 'Exportar' : 'Salvar';
    const description = exportBeforeSave
      ? 'Deseja salvar o modelo como anexo? Se sim, informe o nome que o pdf receberá.'
      : 'Deseja informar o nome que o pdf receberá?';

    alert({
      type: 'question',
      title,
      html: '12345',
      text: description,
      input: 'text',
      inputValidator: (value: string) => {
        if (!value) return 'O nome do pdf é obrigatório';

        if (!SlugUtils.isValidSlug(value)) {
          return `O Nome do pdf não pode possuir caracteres especiais, sugestão: ${SlugUtils.slugify(
            value
          )}`;
        }

        return null;
      },
      onConfirm: (value: string) => {
        onSave(currentTemplate, value);
        if (exportBeforeSave) onExport(currentTemplate);
      },
      onCancel: () => {
        if (!exportBeforeSave) onSave(currentTemplate, modelo?.nome);
        if (exportBeforeSave) onExport(currentTemplate);
      }
    });
  };

  return showQuestion;
};
