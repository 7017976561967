const slugify = (text: string) => {
  return String(text)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '_')
    .replace(/[^\w\s-]/g, '')
    .replace(/_+/g, '_')
    .toLowerCase();
};

const isValidSlug = (text: string) => {
  const normalizedText = String(text)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '*');

  const regex = /^[A-Za-z0-9 _-]+$/;
  return regex.test(normalizedText);
};

export const SlugUtils = {
  slugify,
  isValidSlug
};
