export enum ProgressStatus {
  STARTING = 'STARTING',
  PROGRESS = 'PROGRESS',
  FAILED = 'FAILED',
  SUCCEED = 'SUCCEED'
}

export const progressStatusDescriptions = {
  [ProgressStatus.STARTING]: 'Iniciando',
  [ProgressStatus.PROGRESS]: 'Progresso',
  [ProgressStatus.FAILED]: 'Falhou',
  [ProgressStatus.SUCCEED]: 'Concluído'
};
